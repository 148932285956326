<template>
    <div v-loading.fullscreen.lock="fullscreenLoading">
        <!-- <h1>{{ this.state }}</h1>
        <h1>{{ this.code }}</h1> -->
        <div class="mian-nav" v-if="showtip">
            <p class="title">您正在激活「清橙创客」课程</p>
            <div class="tips">
                <p>清橙创客提醒您：</p>
                <p>1.此二维码最多可支持{{ active }}/{{ limit }}个账号激活</p>
                <p>2.课程激活后，您可以通过公众号或者使用浏览器打开qc.kulf.cn登陆学习</p>
            </div>
            <div class="tip-btn">
                <el-button type="info" plain disabled round>二维码已失效</el-button>
            </div>

        </div>
        <div class="err-mav" v-if="showerror">
            <el-result icon="info" title="信息提示" :subTitle="errmsg">
            </el-result>
        </div>
    </div>
</template>

<script>
import { authback } from "../../api";
export default {
    data() {
        return {
            state: this.$route.query.state,
            code: this.$route.query.code,
            fullscreenLoading: false,
            limit: 0,
            active: 0,
            showtip: false,
            errmsg: '',
            showerror: false,
        };

    },
    mounted() {
        this.anth();
    },
    methods: {
        anth() {
            const self = this;
            console.log(self.state);
            console.log(self.code);
            authback({ code: this.code, state: this.state }).then(res => {
                this.fullscreenLoading = false;
                console.log(res);
                if (res.code == 1) {
                    sessionStorage.setItem("userinfo", res.data.userinfo);
                    sessionStorage.setItem("token", res.data.userinfo.token);
                    localStorage.setItem("token1",res.data.userinfo.token);
                    self.$router.replace("/course");
                } else if (res.code == 10001) {
                    this.showtip = true;
                    this.limit = res.data.limit;
                    this.active = res.data.active;
                } else {
                    //self.$message.error(res.msg);
                    self.showerror = true;
                    self.errmsg = res.msg;
                }
            }).catch(err => {

                console.log(err);
                //self.$message.error('登陆异常，请联系客服');
                //self.$router.push(`/index`);
                self.showerror = true;
                self.errmsg = '登陆异常，请联系客服'+err;

            })
        },
    }
}
</script>

<style scoped>
.mian-nav {
    margin-top: 100px;
}

.mian-nav .title {
    font-size: 24px;
    text-align: center;
}

.mian-nav .tips {
    width: 60%;
    margin: 0 auto;
    padding-top: 80px;
    color: #b0b0b0;
    font-size: 16px;
    line-height: 35px;
}

.tip-btn {
    text-align: center;
    padding-top: 90px;
}

.err-mav {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>